import React from "react";
import Layout from "@components/Layout";
import Introduction from "@components/Introduction";
import { graphql } from "gatsby";

export default ({ location, data }) => (
  <Layout location={location}>
    <Introduction
      projects={data.allContentfulProject.nodes}
      commitments={data.allContentfulCommitments.nodes}
    />
  </Layout>
);

export const pageQuery = graphql`
  query {
    allContentfulCommitments(
      sort: { fields: [startDate, endDate], order: DESC }
    ) {
      nodes {
        startDate
        endDate
        place
        role
        current
      }
    }
    allContentfulProject {
      nodes {
        name
        techStack
        url
        description {
          description
        }
        createdAt
      }
    }
  }
`;
